import React from 'react';
import './App.css';
import { Switch, Route } from 'react-router-dom';
import Privacy from './Footer/Privacy';
import Terms from './Footer/Terms';
import Disclaimer from './Footer/Disclaimer';
import MainScreen from './MainScreen/MainScreen';
import Eula from './Footer/Eula';
import NotFoundPage from './404/NotFoundPage';
import Login from './Authentification/Login';
import { AuthProvider } from './Authentification/Auth';
import PrivateRoute from './Authentification/PrivateRoute';
import Admin from './Admin/Admin';

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <Switch>
          <Route exact path="/" component={MainScreen} />
          <Route path="/privacy-policy" component={Privacy} />
          <Route path="/terms-and-conditions" component={Terms} />
          <Route path="/disclaimer" component={Disclaimer} />
          <Route path="/eula" component={Eula} />
          <PrivateRoute path="/admin-admin" component={Admin} />
          <Route path="/login-login" component={Login} />
          <Route path="*" component={NotFoundPage} />
        </Switch>
      </AuthProvider>
    </div>
  );
}

export default App;


import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import './Footer.css'
import Messenger from './Messenger';

export default class Footer extends Component {
  render() {
    return (
      <div className="footer">
        <Link to={'/privacy-policy'}>
          <p className="text-footer">Privacy Policy</p>
        </Link>
        <Link to={'/terms-and-conditions'}>
          <p className="text-footer">Terms & conditions</p>
        </Link>
        <Link to={'/eula'}>
          <p className="text-footer">EULA</p>
        </Link>
        <Link to={'/disclaimer'}>
          <p className="text-footer">Disclaimer</p>
        </Link>
        <p className="made"><a href="mailto:contact@atlas-sport.app?subject=Request">Contact</a></p>
        <p className="made">Made with ❤ by <a href="https://lilwakko.com" target="_blank" rel="noreferrer">LilWakko</a> - ©Atlas Sport 2021</p>
        <Messenger />
      </div>
    )
  }
}

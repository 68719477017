import React, { Component } from 'react';
import Footer from '../Footer/Footer';
// import Allows from './Allows';
import Info from './Info.js';
import './MainScreen.scss';

export default class MainScreen extends Component {
  render() {
    return (
      <div className="main-screen">
        <Info />
        {/* <Allows /> */}
        <Footer />
      </div>
    )
  }
}

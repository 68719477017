import React, { useCallback, useContext } from "react";
import { withRouter, Redirect } from "react-router";
import firebase from 'firebase/app';
import 'firebase/firestore';
import app from "./Base";
import { AuthContext } from "./Auth";
import { Card, Input, CardText, CardBody, Button, Form, Label } from 'reactstrap';
import './login.scss';
import { Link } from 'react-router-dom';


const Login = ({ history, props }) => {
  const handleLogin = useCallback(
    async event => {
      event.preventDefault();
      const { email, password } = event.target.elements;
      await firebase.firestore().collection("admin").doc(email.value)
        .get()
        .then(doc => {
          if(email.value === process.env.REACT_APP_EMAIL){
            if (doc.data().admin === true) {
              try {
                app
                  .auth()
                  .signInWithEmailAndPassword(email.value, password.value)
                  .then(() => {
                    history.push("/admin-admin");
                  })
                  .catch(error => {
                    if (error.code === 'auth/user-not-found') {
                      alert(`You don't have the authorization to access`)
                      
                    }
                    if (error.code === 'auth/wrong-password') {
                      alert('Wrong password !');
                    };
                  })
              } catch (error) {
                history.push("/");
                alert(error);
              }
            } else {
              alert(`You don't have the authorization to access`);
              history.push("/");
            }
          } else {
            alert(`You don't have the authorization to access`);
            history.push("/");
          }         
        })
    },
    [history]
  );

  const { currentUser } = useContext(AuthContext);

  if (currentUser) {
    return <Redirect to="/admin-admin" />;
  }

  return (
    <div className="login">
      <Form onSubmit={handleLogin}>
        <Card body inverse className="card-style" style={{ backgroundColor: '#003758', border: '1px solid white' }}>
          <CardBody>
            <CardText>
              <Label>
                Email
           <Input name="email" type="email" placeholder="Email" />
              </Label>
              <br />
              <Label>
                Mot de passe
           <Input name="password" type="password" placeholder=" Mot de passe" />
              </Label>
              <br />
              <Button type="submit" color="success">Valider</Button>
              <Link to={"/"} className="text-color nav-link">
                <Button color="warning">Retour accueil</Button>
              </Link>
            </CardText>
          </CardBody>
        </Card>
      </Form>
    </div>
  );
};

export default withRouter(Login);

import React, { Component } from 'react'
import './Info.scss';

export default class Info extends Component {
  render() {
    return (
      <div className='info'>
        <div className="text-info">
          <h1 className="title">Atlas Sport</h1>
          <p className="text-infos" style={{ marginTop: -12 }}>Attempts make success </p>
          <p className="text-infos">
            New way to store your trainings
        <br />
        Don't loose time, juste a take a picture.
        <br />
        Free to use !
        </p>
          <div className="store">
            <a href="https://apps.apple.com/fr/app/atlas-sport/id1506608683" target="_blank" rel="noreferrer">
              <img className='appstore' src={'/images/appstore.png'} alt="appstore" />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.atlas_app" target="_blank" rel="noreferrer">
              <img className='appstore' src={'/images/playstore.png'} alt="playstore" />
            </a>
          </div>
          <div className="store" style={{marginTop: '10px'}}>
            <a href="https://www.instagram.com/atlassport.app/" target="_blank" rel="noreferrer">
              <img className='appstore' src={'/images/instagram.png'} alt="instagram" />
            </a>
            <a href="https://www.facebook.com/AtlasSportApp" target="_blank" rel="noreferrer">
              <img className='appstore' src={'/images/facebook.png'} alt="facebook" />
            </a>
          </div>
        </div>
        <div>
          <img className='mockup' rel="preload" as="image" src={'/images/screen.png'} alt="mockup" />
        </div>
      </div>
    )
  }
}

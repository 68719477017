import React from 'react';
import { Link } from 'react-router-dom';

class NotFoundPage extends React.Component{
    render(){
        return <div style={{textAlign:"center", paddingTop:'20%', color:"wheat", textShadow:'4px 4px 0 black', minHeight:'100vh'}}>
          <h1>Oupsss are you lost ?</h1>
            <p>
              <Link to="/"><button type="button" className="btn btn-success">Go back to main page </button></Link>
            </p>
          </div>;
    }
}
export default NotFoundPage;
import React, { Component } from 'react';
import MessengerCustomerChat from 'react-messenger-customer-chat';

export default class Messenger extends Component {
  render() {
    return (
      <div style={{ marginTop: 20 }}>
        {/* <div class="fb-customerchat"
          page_id={process.env.REACT_APP_MESSENGER_ID}>
        </div> */}
        <MessengerCustomerChat
          pageId={process.env.REACT_APP_MESSENGER_ID}
          appId={process.env.REACT_APP_MESSENGER_APP_ID}
        />
      </div>
    )
  }
}

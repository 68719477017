import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import './infoAdmin.scss';

function InfoAdmin(props) {
  const [countries, setCountries] = useState()
  const [trainings, setTrainings] = useState(0)
  const [weights, setWeights] = useState(0)
  const [records, setRecords] = useState(0)
  const [nbrUsers, setNbrUsers] = useState(0)
  const [os, setOs] = useState({ 'ios': 0, 'android': 0 })

  useEffect(() => {
    let arrayCountry = [];
    let totalTrainings = 0;
    let totalWeights = 0;
    let totalRecords = 0;
    let totalUsers = 0;
    let ios = 0;
    let android = 0;
    let os;
    firebase.firestore().collection('athletes')
      .get()
      .then(querySnapshot => {
        if (querySnapshot.size > 0) {
          querySnapshot.forEach(doc => {
            doc.data().os === 'ios' ? ios = ios + 1 : android = android + 1;
            totalUsers = totalUsers + 1;
            totalTrainings = totalTrainings + doc.data().totalTrainings;
            totalRecords = totalRecords + doc.data().totalRecords;
            if (!arrayCountry.find(x => x.country === doc.data().country)) {
              arrayCountry.push({ 'country': doc.data().country, 'city': [doc.data().city] })
            } else {
              for (let i = 0; i < arrayCountry.length; i++) {
                if (arrayCountry[i].country === doc.data().country && arrayCountry[i].city !== doc.data().city) {
                  arrayCountry[i].city.push(doc.data().city)
                }
              }
            }
            doc.data().unit_weight === 'lbs' ?
              totalWeights = totalWeights + Math.round((doc.data().totalWeights * 0.453592))
              : totalWeights = totalWeights + doc.data().totalWeights
          })
          os = { 'ios': ios, 'android': android };
          setOs(os);
          setTrainings(totalTrainings);
          setWeights(totalWeights);
          setRecords(totalRecords);
          setNbrUsers(totalUsers)
          setCountries(arrayCountry);
        }
      });
  }, [])

  return (
    <div>
      <div className='flex'>
        <div>
          <p className='titleInfo'>Users</p>
          <p className='infoSpec'>{nbrUsers}</p>
        </div>
        <div>
          <p className='titleInfo'>Trainings</p>
          <p className='infoSpec'>{trainings}</p>
        </div>
        <div>
          <p className='titleInfo'>Weights</p>
          <p className='infoSpec'>{weights} kg</p>
        </div>
        <div>
          <p className='titleInfo'>Records</p>
          <p className='infoSpec'>{records}</p>
        </div>
      </div>
      <div className='flex'>
        <div>
          <p className='titleInfo'>OS</p>
          <p className=''>ios : {os.ios}</p>
          <p className=''>android : {os.android}</p>
        </div>
        <div>
          <p className='titleInfo'>Location</p>
          {countries ? countries.map((e, index) =>
          (
            e.country ?
              <div key={index}>
                <div><p className='country'>{e.country} :</p>
          {e.city.map((f, index) => (
                  <p className='city' key={index}>{f}</p>
                ))}
                </div>
              </div> : null
          )
          ) : 0}
        </div>
      </div>
    </div>
  )
}

export default InfoAdmin

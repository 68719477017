import React, { Component } from 'react';
import app from '../Authentification/Base';
import './admin.scss';
import { Button } from 'reactstrap';
import InfoAdmin from './InfoAdmin';

export default class Admin extends Component {
  render() {
    return (
      <div className="admin">
        <h1>Admin</h1>
        <Button color="danger" onClick={() => app.auth().signOut()} className='buttonPosition'>Déconnexion</Button>
        <InfoAdmin/>
      </div>
    )
  }
}

